import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'users_3_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="users_3_outline_20"><path clip-rule="evenodd" d="M10 7.75a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM7.25 6.5a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zm-.5 7.25c0-.416.231-.828.796-1.172C8.12 12.23 8.975 12 10 12c1.025 0 1.881.23 2.454.578.565.344.796.756.796 1.172 0 .293-.1.444-.218.54-.142.113-.396.21-.782.21h-4.5c-.386 0-.64-.097-.781-.21-.12-.096-.219-.247-.219-.54zM10 10.5c-1.225 0-2.369.27-3.234.797-.872.531-1.516 1.369-1.516 2.453 0 .707.276 1.306.781 1.71.484.387 1.105.54 1.719.54h4.5c.614 0 1.235-.153 1.718-.54.507-.404.782-1.003.782-1.71 0-1.084-.643-1.922-1.516-2.453-.865-.526-2.01-.797-3.234-.797zm4.006-5.588a.75.75 0 01.848-.637A2.5 2.5 0 0114.5 9.25a.75.75 0 010-1.5 1 1 0 00.143-1.99.75.75 0 01-.637-.848zM15.749 10a.75.75 0 000 1.5c1.158 0 1.75.673 1.75 1.25a.86.86 0 01-.185.547.57.57 0 01-.464.203.75.75 0 000 1.5c1.428 0 2.15-1.21 2.15-2.25 0-1.712-1.607-2.75-3.251-2.75zM5 10.75a.75.75 0 00-.75-.75C2.606 10 .999 11.038.999 12.75c0 1.04.722 2.25 2.15 2.25a.75.75 0 000-1.5.57.57 0 01-.465-.203.86.86 0 01-.185-.547c0-.577.592-1.25 1.751-1.25a.75.75 0 00.75-.75zm.142-6.474a.75.75 0 01.214 1.484A1 1 0 005.5 7.75a.75.75 0 110 1.5 2.5 2.5 0 01-.358-4.975z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20Users3OutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20Users3Outline: FC<Icon20Users3OutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20Users3Outline as any).mountIcon = mountIcon;

export default Icon20Users3Outline;
